<template>
<main>
  <section class="section">
    <div>
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation-notes" :bid="bid" :rid="rid">{{$t('breadcrumb_relation_notes', relation)}}</sqr-crumb>
        </ul>
      </nav>
    </div>
  </section>
  <section class="section" v-if="!loading && !error">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
          <div>
            <div class="field">
              <sqr-input-textarea label="relation_notes" :value="relation.notes" @change="fieldSet({field: 'notes', value: $event})" :rows="10"/>
            </div>
            <div class="field">
              <div class="buttons is-pulled-right">
                <sqr-button label="buttons_cancel" @click="$router.back()" color="light" size="medium"/>
                <sqr-button label="buttons_save" @click="saveAndGo()" color="primary" size="medium" :is-loading="saving"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';

import relation from './relation';

import SqrButton from '../sqrd/SqrButton';
import SqrInputText from '../sqrd/SqrInputText';
import SqrInputTextarea from '../sqrd/SqrInputTextarea';

export default {
  name: 'RelationNotes',
  mixins: [relation],
  components: {SqrInputText, SqrInputTextarea},
  props: {
    bid: String,
    id: String
  },
  computed: {
    ...mapGetters('relation', ['loading', 'saving', 'error']),
    bankAccount () {
      return this.relation && this.relation.bankAccount;
    },
    title () {
      if (!this.relation) return '';
      return this.$t('relation_title', Object.assign({}, this.relation, {
        status: this.$t('relation_status_' + this.relation.status)
      }));
    },
    subtitle () {
      if (!this.relation || !this.relation.birthDate) return '';
      return this.$t('relation_subtitle', {birthDate: date(this.relation.birthDate)});
    }
  },
  mounted () {
    this.sub({path: ['brokers', this.bid, ], id: this.id});
  },
  methods: {
    ...mapMutations('relation', ['fieldSet']),
    ...mapActions('relation', ['sub', 'save']),
    saveAndGo () {
      this.save().then(() => this.$router.back());
    }
  },
  metaInfo () {
    return {
      title: this.title
    };
  }
}
</script>

